import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import favicon from "../images/favicon.ico"

import rph2rphhero from "../images/marketing/svg/rph2rph/rph2rph-hero.svg"
import rph2rphillus from "../images/marketing/svg/rph2rph/rph2rph-illus.svg"
import rph2rphtab from "../images/marketing/svg/rph2rph/rph2rph-tab.png"
import rph2rphsellertab from "../images/marketing/svg/rph2rph/rph2rph-seller-tab.png"

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>EzriRx.com - RPh2RPh - Pharmacy To Pharmacy Marketplace</title>
      <meta
        name="keywords"
        content="pharmacy to pharmacy, pharmacy to pharmacy marketplace, pharmacy to pharmacy portal, pharmacy purchasing platform, ezrirx, ezrirph2rph, ezrirh2rh"
      />
      <meta
        name="description"
        content="EzriRx - RPh2RPh - A Pharmacy-to-Pharmacy marketplace for pharmacies to buy and sell overstock products from each other within EzriRx!"
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://s3.amazonaws.com/ezrirx-assets/adcards/RpH2RpH_og.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="EzriRx RPh2RPh" />
      <meta property="og:title" content="EzriRx RPh2RPh | Pharmacy To Pharmacy Marketplace" />
      <meta property="og:description" content="A Pharmacy-to-Pharmacy marketplace for pharmacies to buy and sell overstock products from each other within EzriRx!" />
      <meta property="og:url" content="https://www.ezrirx.com/RPh2RPh/" />
      <link rel="canonical" href="https://www.ezrirx.com/pharmacy-to-pharmacy-marketplace/" />
      <link rel="shortcut icon" type="image/png" href={`${favicon}`} />
    </Helmet>
    <Layout>
      <div className="body">
        <section className="hero-banner" id="hero-rph2rph">
          <div className="contents">
            <div className="hero-banner__inner">
              <div className="hero-content">
                <h1>
                  Buy and Sell on the RPh2RPh Pharmacy-to-Pharmacy Shop right on
                  the EzriRx Marketplace.
                </h1>
                <p>
                  Provided that it’s for a ‘Specific patient need’, pharmacies
                  can buy overstock from one another. Why return it when another
                  pharmacy can use it? List, Sell, Save! Search, Buy, Save!
                </p>
                <a
                  className="button"
                  href="https://app.ezrirx.com/signup/pharmacy/?utm_source=Marketing&utm_medium=RPh2RPh_Landing"
                >
                  Register via EzriRx
                </a>
              </div>
              <div className="hero-image">
                <img
                  src={rph2rphhero}
                  alt="Buy and Sell on the RPh2RPh Pharmacy-to-Pharmacy Shop right on the EzriRx Marketplace."
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="single-col">
              <div className="col col--text">
                <div className="title-box text-center">
                  <h2>A legal way to sell drugs to other pharmacies</h2>
                </div>
                <p className="text-center">
                  RPh2RPh is a Pharmacy-to-Pharmacy marketplace within EzriRx
                  that allows pharmacies with a awaiting script to purchase from
                  another pharmacy in their state.*
                  <small>varies by State</small><br/>Why return it and pay
                  restocking fees or use a returns company and lose? Think
                  Win-Win!
                </p>
              </div>
              <div className="col col--img">
                <img
                  src={rph2rphillus}
                  alt="A legal way to sell drugs to other pharmacies"
                  className="width-auto"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="single-col">
              <div className="col col--text">
                <div className="title-box text-center">
                  <h2 className="title-text">How does it work?</h2>
                </div>
              </div>
            </div>
            <div className="table-2cols align-top table-2cols--mobile">
              <div className="col col--img text-center">
                <img
                  src={rph2rphtab}
                  alt="How to buy from another pharmacy?"
                  className="width-auto"
                ></img>
              </div>
              <div className="col col--text">
                <div className="title-box">
                  <h2>How to buy from another pharmacy?</h2>
                </div>
                <p>
                  To become a buyer on RPh2RPh you need to have a free EzriRx
                  account. The application process is simple and you can add
                  products to your cart with a verified bank account on file.
                </p>
                <p>
                  Whenever a pharmacy is selling a product it will show up in a
                  newly created tab within the marketplace.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="marketing-content">
          <div className="contents">
            <div className="table-2cols align-top">
              <div className="col col--text">
                <div className="title-box">
                  <h2>How to sell to another pharmacy?</h2>
                </div>
                <p>
                  To become a seller on EzriRx RPh2RPh, you need to apply for an
                  RPh2RPh ‘Seller account’ and once approved, set your shipping
                  fee parameters &amp; start receiving orders.
                </p>
              </div>
              <div className="col col--img text-center">
                <img
                  src={rph2rphsellertab}
                  alt="Receive credit and request credit limit increases"
                  className="width-auto"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section className="rph2rph-faq">
          <div className="contents">
            <div className="content-wrapper">
              <div className="content-left">
                <p className="mb-3 mb-lg-0">
                  We encourage pharmacies interested in selling to go through
                  our detailed How-To and FAQ articles to learn more about the
                  laws and processes.
                </p>
              </div>
              <div className="content-right">
                <a
                  rel="nofollow"
                  href="https://intercom.help/ezrirx/en/articles/4885919-pharmacy-to-pharmacy-all-there-is-to-know?utm_source=Marketing&utm_medium=RpH2RpH_Landing"
                  className="button white"
                >
                  Read our FAQ's
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </>
)
